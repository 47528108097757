import { lazy } from 'react';
import WithPermissions from '../HOC/withPermissions.tsx';
import { EPermissions } from '../../types/enum/permissions.ts';

export type TWidgetComponentMappingKeys = keyof typeof widgetComponentMapping;

export const widgetComponentMapping = {
  'search-component': lazy(
    () => import('../../components/widgets/searchWidget.tsx'),
  ),
  'alert-component': lazy(
    () => import('../../components/widgets/alertWidget.tsx'),
  ),
  'links-component': lazy(
    () => import('../../components/widgets/linksWidget.tsx'),
  ),
  'service-requests-component': lazy(
    () =>
      import(
        '../../components/widgets/serviceRequests/serviceRequestsWidget.tsx'
      ),
  ),
  'incidents-component': WithPermissions(
    lazy(() => import('../../components/widgets/incidentsWidget.tsx')),
    EPermissions.fieldServiceCasesRead,
  ),
  'incidents-table-component': WithPermissions(
    lazy(() => import('../../components/incidents/table/incidentsTable.tsx')),
    EPermissions.fieldServiceCasesRead,
  ),
  'incident-details-component': WithPermissions(
    lazy(() => import('../../components/incidents/incidentDetails.tsx')),
    EPermissions.fieldServiceCasesRead,
  ),
  'create-field-service-case-component': WithPermissions(
    lazy(() => import('../../components/createIncidents/createIncident.tsx')),
    EPermissions.fieldServiceCasesWrite,
    <div>Unauthorized</div>,
  ),
  'create-bulk-incidents-component': WithPermissions(
    lazy(
      () =>
        import('../../components/createIncidents/bulk/createBulkIncident.tsx'),
    ),
    EPermissions.fieldServiceCasesWrite,
    <div>Unauthorized</div>,
  ),
  'devices-table-component': WithPermissions(
    lazy(() => import('../../components/devices/table/devicesTable.tsx')),
    EPermissions.devicesRead,
  ),
  'device-details-component': WithPermissions(
    lazy(() => import('../../components/devices/deviceDetails.tsx')),
    EPermissions.devicesRead,
  ),
  'users-table-component': WithPermissions(
    lazy(() => import('../../components/users/table/usersTable.tsx')),
    EPermissions.usersRead,
  ),
  'user-details-component': WithPermissions(
    lazy(() => import('../../components/users/userDetails.tsx')),
    EPermissions.usersRead,
  ),
  'institutions-table-component': lazy(
    () => import('../../components/institutions/table/institutionsTable.tsx'),
  ),
  'institution-details-component': lazy(
    () => import('../../components/institutions/detail/institutionDetail.tsx'),
  ),
  'contracts-table-component': WithPermissions(
    lazy(() => import('../../components/contracts/table/contractsTable.tsx')),
    EPermissions.contractsRead,
  ),
  'os-images-table-component': WithPermissions(
    lazy(() => import('../../components/osImages/osImagesTable.tsx')),
    EPermissions.osImagesRead,
  ),
  'api-credentials-table-component': WithPermissions(
    lazy(
      () =>
        import('../../components/apiCredentials/table/apiCredentialsTable.tsx'),
    ),
    EPermissions.credentialsRead,
  ),
  'create-api-credential-component': WithPermissions(
    lazy(
      () => import('../../components/apiCredentials/requestApiCredentials.tsx'),
    ),
    EPermissions.credentialsWrite,
  ),
  'webshop-orders-table-component': WithPermissions(
    lazy(
      () =>
        import('../../components/webshopOrders/table/webshopOrdersTable.tsx'),
    ),
    EPermissions.webshopOrdersRead,
  ),
  'deliveries-table-component': WithPermissions(
    lazy(() => import('../../components/deliveries/table/deliveriesTable.tsx')),
    EPermissions.webshopOrdersRead,
  ),
  'deliveries-detail-component': WithPermissions(
    lazy(() => import('../../components/deliveries/deliveryDetails.tsx')),
    EPermissions.webshopOrdersRead,
  ),
  'institution-orders-table-component': WithPermissions(
    lazy(
      () =>
        import(
          '../../components/institutionOrders/table/institutionOrdersTable.tsx'
        ),
    ),
    EPermissions.forecastsRead,
  ),
  'search-results-table-component': lazy(
    () => import('../../components/search/searchResults.tsx'),
  ),
  'device-loans-table-component': WithPermissions(
    lazy(
      () => import('../../components/loanDevices/table/loanDevicesTable.tsx'),
    ),
    EPermissions.deviceLoansRead,
  ),
  'permissions-component': lazy(
    () =>
      import(
        '../../components/admin/platformUsers/permissions/permissions.tsx'
      ),
  ),
  'webshops-table-component': WithPermissions(
    lazy(() => import('../../components/webshops/table/webshopsTable.tsx')),
    EPermissions.webshopOrdersRead,
    <div>Unauthorized</div>,
  ),
  'webshops-configuration-component': lazy(
    () => import('../../components/webshops/configuration/configuration.tsx'),
  ),
  'orgchart-component': lazy(
    () => import('../../components/orgChart/orgChart.tsx'),
  ),
  'institution-tags-component': lazy(
    () =>
      import(
        '../../components/institutionGroupTags/table/institutionGroupTagsTable.tsx'
      ),
  ),
  default: lazy(
    () => import('../../components/widgets/helpers/skeletonWidget.tsx'),
  ),
} as const;

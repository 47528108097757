import { ReactElement } from 'react';
import {
  IconDefinition,
  faBoxOpen,
  faBoxesPacking,
  faBuildingColumns,
  faCircleInfo,
  faCompactDisc,
  faFileCircleQuestion,
  faFileContract,
  faLaptopMedical,
  faObjectsColumn,
  faStore,
  faRectangleCode,
  faPersonDolly,
} from '@fortawesome/pro-solid-svg-icons';
import { faUsers, faBox } from '@fortawesome/pro-duotone-svg-icons';

const iconMap: { [key: string]: IconDefinition } = {
  'aa87d56d-b914-48bc-9ad3-3a4d93603206': faObjectsColumn,
  '9dcf4b48-5cbf-404c-9fc0-8fa378a91b61': faLaptopMedical,
  '7043420c-dd3b-471a-bd55-670c469d9bf8': faBoxOpen,
  'fd9fc183-bbe2-49f9-8aae-d733b54dd78f': faBuildingColumns,
  '30f38f99-5f93-4784-bbcb-0574d148da57': faCircleInfo,
  'b216f1d3-a6b8-4699-bc72-15f2dbf222a5': faFileContract,
  '3c0dfcbe-5bda-424d-aa4e-97ec6e476d38': faUsers,
  'e01278b8-ba97-4ba4-b238-654ec7cd7f30': faCompactDisc,
  'd4196672-303e-4ecd-b69a-8d8d9149830a': faBuildingColumns,
  'f27162f1-4d8a-478f-959d-acb3503e52ca': faObjectsColumn,
  '552a19d0-d89e-40e6-a2d5-64f20f9cb3f0': faCompactDisc,
  'bf4824b6-28eb-406b-9a15-b36c6d3db507': faRectangleCode,
  '79eede26-bf4b-4759-aa74-4bb6b2ab42df': faBoxesPacking,
  'f704b05d-ad58-4948-9031-6653ed534eb8': faStore,
  'e903104c-f1f3-4662-9dad-f87f746729ad': faBox,
  '01807704-88ff-4a49-89de-59a3c40d6cd6': faPersonDolly,
};

type TRenderIcon = {
  render: (Icon: IconDefinition) => ReactElement;
  guid: string;
};

export const RenderIcon = ({ render, guid }: TRenderIcon) => {
  return render(iconMap[guid] || faFileCircleQuestion);
};

export enum EPermissions {
  // Read --------------------------
  devicesRead = 'Devices.Read',
  credentialsRead = 'Credentials.Read',
  fieldServiceCasesRead = 'FieldService.Cases.Read',
  problemTypesRead = 'FieldService.ProblemTypes.Read',
  institutionsContactsRead = 'Institutions.Contacts.Read',
  institutionsAddressesRead = 'Institutions.Addresses.Read',
  contractsRead = 'Contracts.Read',
  usersRead = 'Users.Read',
  osImagesRead = 'OsImages.Read',
  deviceLoansRead = 'DeviceLoans.Read',
  institutionsRead = 'Institutions.Read',
  institutionsAccountsRead = 'Institutions.Accounts.Read',
  institutionsRolesRead = 'Institutions.Roles.Read',
  usersTypesRead = 'Users.Types.Read',
  webshopOrdersRead = 'WebshopOrders.Read',
  forecastsRead = 'Forecasts.Read',
  webshopConfigsRead = 'WebshopConfigs.Read',

  // Write --------------------------
  devicesWrite = 'Devices.Write',
  deviceLoansWrite = 'DeviceLoans.Write',
  credentialsWrite = 'Credentials.Write',
  fieldServiceCasesWrite = 'FieldService.Cases.Write',
  institutionsWrite = 'Institutions.Write',
  institutionsAccountsWrite = 'Institutions.Accounts.Write',
  institutionsAddressesWrite = 'Institutions.Addresses.Write',
  institutionsContactsWrite = 'Institutions.Contacts.Write',
  usersWrite = 'Users.Write',
  webshopConfigsWrite = 'WebshopConfigs.Write',

  // Other --------------------------
  institutionsSetAsParent = 'Institutions.SetAsParent',
  institutionsSetAsBillingParent = 'Institutions.SetAsBillingParent',
  devicesSearch = 'Devices.Search',
}

import {
  Navigate,
  redirect,
  redirectDocument,
  RouteObject,
} from 'react-router-dom';
import { routingComponentMapping } from '../helpers/componentMappings';
import App from '../app';
import { AuthProvider } from '../helpers/authProvider';
import { StrictMode } from 'react';
import { TRouting } from '../redux/slices/routingSlice';
import PlatformUsersTable from '../components/admin/impersonate/impersonationTable';
import InstitutionAccessTable from '../components/admin/institutionAccess/institutionAccessTable';
import FailedToSignIn from '@/components/errorHandling/failedToSignIn';
import * as Sentry from '@sentry/react';

export const createRoutes = (routingData: TRouting[]): RouteObject[] => {
  return [
    {
      path: '/',
      Component: () => (
        <StrictMode>
          <App />
        </StrictMode>
      ),
      id: 'root',
      shouldRevalidate: () => true,
      loader: async () => {
        const redirectUrl = await AuthProvider.checkAuthentication();
        if (redirectUrl) return redirect(redirectUrl);
        return {};
      },
      errorElement: <div>No access</div>,
      children: [
        ...routingData.map((route) => ({
          path: route.path,
          Component: () => {
            const LazyComp =
              routingComponentMapping[route.guid] ??
              routingComponentMapping.widgetView;
            return <LazyComp guid={route.guid} />;
          },
        })),
        {
          path: 'signout',
          loader: () => {
            return redirect(AuthProvider.signout());
          },
        },
        AuthProvider.decodedToken &&
        AuthProvider.decodedToken.impersonation === 'true'
          ? {
              path: 'impersonate',
              Component: () => <PlatformUsersTable />,
            }
          : {},
        AuthProvider.decodedToken &&
        AuthProvider.decodedToken.institution_access
          ? {
              path: 'institutionaccess',
              Component: () => <InstitutionAccessTable />,
            }
          : {},
        {
          path: '*',
          Component: () => <div>not found</div>,
        },
      ],
    },
    {
      path: 'signin-callback',
      loader: async ({ request }) => {
        const url = new URL(request.url);
        const { codeVerifierKey, previousPathname, retry } = JSON.parse(
          url.searchParams.get('state') ?? '{}',
        );
        if (!codeVerifierKey) return redirectDocument('/');
        try {
          await AuthProvider.validateSignin(
            url.searchParams.get('code'),
            codeVerifierKey,
          );
          if (AuthProvider.isAuthenticated)
            return redirect(
              previousPathname.includes('signin-callback')
                ? '/'
                : previousPathname,
            );
        } catch (error) {
          if (retry) return redirectDocument('/');
          Sentry.captureException(error);
        }
      },
      Component: () => null,
      errorElement: <FailedToSignIn />,
    },
    {
      path: 'upload/incidents/:guid',
      lazy: async () => {
        const { FileUpload } = await import('../views');
        return { Component: FileUpload };
      },
    },
    {
      path: '*',
      Component: () => <Navigate to='/' />,
    },
  ];
};

import { LazyExoticComponent, lazy, JSX } from 'react';
import { TViewProps } from '../../views/private';

type TRoutingComponentMapping = Readonly<
  Record<string, LazyExoticComponent<(props: TViewProps) => JSX.Element>>
>;

export const routingComponentMapping: TRoutingComponentMapping = {
  widgetView: lazy(() =>
    import('../../views/private').then(({ WidgetView }) => ({
      default: WidgetView,
    })),
  ),
};

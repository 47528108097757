import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import {
  TCustomStateClickOutside,
  useClickOutside,
} from '../../hooks/useClickOutside';
import { RenderIcon } from '../../helpers/renderProps/renderIcon';
import { setMenu } from '../../redux/slices/menuSlice';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { hasCorrectPermissions } from '../../helpers/permissions';
import NavItem from './navItem';

const SideBar = () => {
  const dispatch = useDispatch();
  const [openParent, setOpenParent] = useState<string>('');
  const userPermissions = useSelector((state: RootState) => state.permissions);
  const showMenu = useSelector((state: RootState) => state.menu);
  const routing = useSelector((state: RootState) => state.routing);
  const [sidebarRef] = useClickOutside<
    HTMLDivElement,
    TCustomStateClickOutside
  >({
    ignoreClass: 'ignoreClickOutside',
    customState: showMenu,
    customToggleState: (value: boolean) => dispatch(setMenu(value)),
  });

  return (
    <aside
      ref={sidebarRef}
      className={`print:hidden bg-app-800 z-50 flex flex-col gap-16 lg:sticky h-dynamic top-0 left-0 sidebar fixed transition-transform lg:translate-x-0 ${
        !showMenu ? '-translate-x-full' : ''
      }`}
      data-cy='sidebarNavigationContainer'>
      <img src={logo} alt='logo' className='py-8 w-1/2 mx-auto' />
      <nav className='pb-8'>
        <menu>
          <ul>
            {routing
              .filter((route) => route.visibleInSideMenu)
              .filter((route) =>
                hasCorrectPermissions(userPermissions, route.permissions),
              )
              .map((route) => (
                <li
                  className={`${route.childPages.length > 0 && openParent === route.guid ? 'bg-app-700/60 shadow-app-600' : 'bg-app-800'}`}
                  key={route.guid}
                  data-cy={`sidebarNavigationLink${route.guid}`}>
                  {route.childPages.length > 0 ? (
                    <>
                      <Link
                        className='nav-item'
                        to={route.path}
                        onClick={() => {
                          setOpenParent(route.guid);
                        }}>
                        <RenderIcon
                          render={(Icon) => (
                            <FontAwesomeIcon
                              icon={Icon}
                              className='w-full h-[30px] object-contain group-[.isActive]:text-signpost'
                            />
                          )}
                          guid={route.guid}
                        />
                        <span className='whitespace-nowrap overflow-hidden text-ellipsis w-full col-start-2'>
                          {route.displayName}
                        </span>
                        <FontAwesomeIcon
                          icon={faCaretDown}
                          className={`${openParent === route.guid ? 'rotate-180' : ''}`}
                        />
                      </Link>
                      <div
                        className={`${openParent === route.guid ? 'block' : 'hidden'}`}>
                        <NavItem
                          route={route}
                          child
                          onClick={() => dispatch(setMenu(false))}
                        />
                        {route.childPages.map((child) => (
                          <NavItem
                            route={child}
                            child
                            key={child.guid}
                            onClick={() => dispatch(setMenu(false))}
                          />
                        ))}
                      </div>
                    </>
                  ) : (
                    <NavItem
                      route={route}
                      onClick={() => {
                        dispatch(setMenu(false));
                        setOpenParent('');
                      }}
                    />
                  )}
                </li>
              ))}
          </ul>
        </menu>
      </nav>
      {/* <span className='absolute bottom-4 left-4 text-app-400 text-sm'>V&nbsp;{process.env.npm_package_version}</span> */}
    </aside>
  );
};

export default SideBar;

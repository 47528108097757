import { useSelector } from 'react-redux';
import { EPermissions } from '../../types/enum/permissions';
import { FC, ReactNode, useContext, JSX } from 'react';
import { RootState } from '../../redux/store';
import { hasCorrectPermissions } from '../permissions';
import { PermissionContext } from '../../context/permissionContext';

type TPermissionWrapperProps = {
  children: ReactNode;
  neededPermissions: EPermissions | EPermissions[];
  displayWarning?: JSX.Element;
};

const PermissionChecker = ({
  children,
  neededPermissions,
  displayWarning,
}: TPermissionWrapperProps) => {
  const currentPermissions = useContext(PermissionContext);
  let availablePermissions = currentPermissions || [];
  const userPermissions = useSelector((state: RootState) => state.permissions);
  if (!currentPermissions) availablePermissions = userPermissions;
  const hasPermissions = hasCorrectPermissions(
    availablePermissions,
    neededPermissions,
  );

  if (!hasPermissions && displayWarning) return displayWarning;
  if (!hasPermissions) return null;
  return children;
};

const WithPermissions = <T extends NonNullable<unknown>>(
  WrappedComponent: FC<T>,
  neededPermissions: EPermissions | EPermissions[],
  displayWarning?: JSX.Element,
) => {
  return (props: T) => {
    return (
      <PermissionChecker
        neededPermissions={neededPermissions}
        displayWarning={displayWarning}>
        <WrappedComponent {...props} />
      </PermissionChecker>
    );
  };
};

export default WithPermissions;

import { lazy, LazyExoticComponent } from 'react';

export const createIncidentFormComponents: Record<
  string,
  LazyExoticComponent<() => JSX.Element>
> = {
  defineDefect: lazy(() =>
    import('../../components/createIncidents').then(({ DefineDefect }) => ({
      default: DefineDefect,
    })),
  ),
  defineBulkDefects: lazy(
    () =>
      import(
        '../../components/createIncidents/bulk/defineDefects/defineBulkDefects'
      ),
  ),
  repairLocation: lazy(() =>
    import('../../components/createIncidents').then(({ RepairLocation }) => ({
      default: RepairLocation,
    })),
  ),
  invoice: lazy(() =>
    import('../../components/createIncidents').then(({ InvoiceData }) => ({
      default: InvoiceData,
    })),
  ),
  requestLoanDevice: lazy(() =>
    import('../../components/createIncidents').then(
      ({ RequestLoanDevice }) => ({
        default: RequestLoanDevice,
      }),
    ),
  ),
  dateSelection: lazy(
    () => import('../../components/createIncidents/bulk/dateSelection'),
  ),
  overview: lazy(() =>
    import('../../components/createIncidents').then(({ OverviewFull }) => ({
      default: OverviewFull,
    })),
  ),
  bulkOverview: lazy(
    () => import('../../components/createIncidents/bulk/bulkOverview'),
  ),
};
